/* You can add global styles to this file, and also import other style files */

@import './scss/material';
@import './scss/utils';
@import './scss/responsive';
@import './scss/variables';
@import './scss/theme';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 13px;
}


@import '@angular/material/theming';
@include mat-core();
$my-app-primary: '#00095b';
/* $my-app-accent:  mat-palette($mat-pink, 500, 900, A100);
$my-app-warn:    mat-palette($mat-deep-orange);
$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent, $my-app-warn);
@include angular-material-theme($my-app-theme);
.alternate-theme {
  $alternate-primary: mat-palette($mat-light-blue);
  $alternate-accent:  mat-palette($mat-yellow, 400);
  $alternate-theme: mat-light-theme($alternate-primary, $alternate-accent);
  @include angular-material-theme($alternate-theme);
} */


/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #767c7f;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555d61;
}

a.mat-list-item.active {
  background-color: #d9dadbb5 !important;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
  background-color: rgb(255 255 255 / 45%);
}

.filterTop {
  padding: 0 20px !important;
}

th.mat-header-cell {
  font-weight: 600;
  color: #000;
}

tr.mat-header-row {
  background-color: #0c4e8f;
}

tr.mat-header-row th {
  color: #ffff;
  font-weight: 500;
  font-size: 15px;
}

.example-full-width {
  width: 100%;
}

.mat-card-content {
  padding: 0 19px;
}

.table_actions {
  padding: 0 0.5rem;
}

.noRecords {
  padding: 10px;
}

.mat-raised-button~.mat-raised-button {
  margin-left: 10px;
}

.cursorPointer {
  cursor: pointer;
}

.preview {
  cursor: pointer;
  text-decoration: underline;
  color: blue;
}

.dialogC {
  position: relative !important;
}

.close-icon {
  position: absolute !important;
  top: 1rem;
  right: 1rem;
  transform: translate(50%, -50%);
}

.addtopem label {
  top: 0.5em;
}

.mat-sort-header-arrow {
  color: #3cff55;
}

.mat-button.mat-red {
  color: #c72a25;
  width: 32%;
  margin: 5px;
}

.mat-raised-button.mat-red,
.mat-flat-button.mat-red {
  background-color: #c72a25;
  color: white;
}

.mat-button.mat-brown {
  color: #834f50;
  width: 32%;
  margin: 5px;
}

.mat-raised-button.mat-brown,
.mat-flat-button.mat-brown {
  background-color: #834f50;
  color: white;
}

.mat-button.mat-green {
  color: #3fb618;
  width: 32%;
  margin: 5px;
}

.mat-raised-button.mat-green,
.mat-flat-button.mat-green {
  background-color: #3fb618;
  color: white;
}

.row.actions {
  width: 100%;
  display: flex;
  margin-top: .5rem;
  flex-wrap: wrap;
}
.width-21{
  width: 21%;
  margin-right: 12px;
}